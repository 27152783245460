<template>
  <div class="comment-form">
    <div class="title mb-4">COMMENT</div>
    <textarea
      rows="1"
      placeholder="Write your comment here"
      class="comment-area"
      ref="comment"
      v-model="g.correction.comment"
      @input="mixin_autoResize_resize"
    ></textarea>
    <button
      v-if="!isReadonly"
      class="btn btn-primary mt-4"
      @click="save"
      :disabled="!g.correction.comment"
    >
      Save
    </button>
  </div>
</template>

<script>
import AutoResize from '../mixins/AutoResize'

export default {
  name: 'CommentForm',
  mixins: [AutoResize],
  props: {
    isReadonly: Boolean
  },
  data() {
    return {
      g: this.$store.state.g
    }
  },
  methods: {
    save() {
      this.g.corrections = this.g.corrections
        .filter(correction => correction.id !== this.g.correction.id)
        .sort((a, b) => {
          if (a.uid < b.uid) return -1
          else if (a.uid > b.uid) return 1
          return 0
        })

      // add item
      this.g.correction.isNew = false // no delete on close tooltip
      this.g.correction.oldHtml = ''
      this.g.correction.newHtml = ''
      this.g.correction.fullHtml = ''
      this.g.corrections.push(this.g.correction)
      this.g.correctionElement = null
      // this.g.popper.destroy()
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.comment-form {
  width: 36vw;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #7d7d7d;
}

.comment-area {
  resize: none;
  min-height: 24px;
  max-height: 200px;
  width: 100%;
  border: 0;
  padding: 0;
  outline: 0 !important;
}
</style>
