<template>
  <div style="margin-right: 16px;">
    <div class="form-check form-check-inline" v-for="item in itemsWithId" :key="item.id">
      <input class="a-input form-check-input"
             type="radio"
             :id="item.id"
             :checked="item.label == modelValue"
             @input="$emit('update:modelValue', item.label)"
      >
      <label class="a-label form-check-label" :for="item.id">{{ item.label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageLevel',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: ''
    },
  },
  emits: ['update:modelValue'],
  data () {
    return {
      items: ['A1', 'A2', 'B1', 'B2']
    }
  },
  computed: {
    itemsWithId () {
      return this.items.map((item) => ({
        id: `ll_${item}`,
        label: item,
      }))
    }
  }
}
</script>

<style scoped>
.a-wrap {
  /*border: 1px solid #ced4da;*/
  /*border-radius: 4px;*/
}
.a-input {
  display: none;
}
.a-label {
  width: 100%;
  text-align: center;
  padding: 7px 12px;
  background: #F0F4F9;
  cursor: pointer;
}
.form-check-inline:first-child .a-label {
  border-radius: 4px 0px 0px 4px;
}
.form-check-inline:last-child .a-label {
  border-radius: 0px 4px 4px 0px;
}
.a-input:checked + .a-label {
  color: white;
  background: #258FFB;
}
.form-check-inline {
  width: 50px;
  padding-left: 0;
  margin-bottom: 0;
  margin-right: 1px;
}
</style>
