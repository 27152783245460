<template>
  <div class="arrow" :class="{ arrow_active: show }">
    <svg
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 0H0L5 5L10 0Z" fill="#495057" />
    </svg>
  </div>
  <transition name="fade">
    <div v-if="show" class="form-helper">
      <template v-if="items?.length">
        <div
          v-for="item in items"
          :key="item"
          class="form-helper-item"
          @click="select(item)"
        >
          {{ item }}
        </div>
      </template>
      <div v-else class="form-helper-item unavaliable">
        No items found
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FormHelper',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  emits: ['select'],
  methods: {
    select(value) {
      this.$emit('select', value)
    }
  }
}
</script>

<style scoped>
.arrow {
  position: absolute;
  top: 7px;
  right: 16px;
  transition: transform 0.3s ease;
}

.arrow_active {
  transform: rotateZ(180deg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.form-helper {
  position: absolute;
  width: 100%;
  padding: 0.3rem 0;
  background-color: #f0f4f9;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.form-helper-item {
  padding: 0.15rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  cursor: pointer;
}

.form-helper-item:hover {
  color: #ffffff;
  background: #258ffb;
}

.unavaliable {
  color: #BFBFBF;
  cursor: not-allowed;
}

.form-helper-item.unavaliable:hover {
  color: #BFBFBF;
  background: none;
}
</style>
