<template>
  <div @click="slider = !slider">
    <div class="title">
      <span class="text-capitalize_mod type">{{ correction.type }}</span>
      <TagList
        v-if="correction.type === 'correction'"
        :value="correction.tags"
      ></TagList>
    </div>
    <div class="slider" v-show="slider">
      <template v-if="correction.type === 'correction'">
        <div class="my-3">
          <CorrectionDiff
            :oldHtml="correction.oldHtml"
            :newHtml="correction.newHtml"
          />
        </div>
        <div class="comment" v-if="correction.comment?.length">
          <div class="comment-label">Comment</div>
          {{ correction.comment }}
        </div>
      </template>
      <div v-else class="comment">
        {{ correction.comment }}
      </div>

      <div class="mt-3">
        <a class="pointer edit" @click="$emit('editThis')">Edit</a>
        <a class="pointer delete" @click="$emit('deleteThis')">Delete</a>
      </div>
    </div>
  </div>
</template>

<script>
import TagList from './TagList'
import CorrectionDiff from './CorrectionDiff'
export default {
  name: 'CorrectionItem',
  components: { CorrectionDiff, TagList },
  props: {
    correction: {
      type: Object,
      required: true
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slider: false
    }
  }
}
</script>

<style scoped>
.type {
  font-size: 14px;
  color: #7d7d7d;
  font-weight: 500;
  margin-right: 8px;
  letter-spacing: 0.045em;
}

.comment {
  margin-top: 8px;
}

.pointer {
  cursor: pointer;
}

.edit {
  color: #007bff;
  margin-right: 20px;
  text-decoration: none;
}

.delete {
  color: #ff5964;
  margin-right: 20px;
  text-decoration: none;
}

.text-capitalize_mod {
  text-transform: uppercase;
  letter-spacing: 0.045em;
}

.comment-label {
  border-top: 1px solid #d9d9d9;
  padding-top: 16px;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: #7d7d7d;
}
</style>
