<template>
  <div v-show="isShow" class="text-editor-tooltip" ref="text_editor_tooltip" v-click-outside="closeByOutside" @mouseup="stop">
    <div v-if="g.correction.type === ''">
      <div class="minus_pad">
        <button class="btn btn-item" @click="setType('correction')">New correction</button>
        <br>
        <button class="btn btn-item" @click="setType('comment')">New comment</button>
        <!--      <br>-->
        <!--      <button class="btn btn-outline-dark btn-item" @click="cancel">Cancel</button>-->
      </div>
    </div>

    <CorrectionForm v-if="g.correction.type === 'correction'" @close="close" @cancel="cancel"></CorrectionForm>
    <CommentForm v-if="g.correction.type === 'comment'" @close="close" @cancel="cancel"></CommentForm>

<!--    <div id="arrow"></div>-->
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import CorrectionForm from './CorrectionForm'
import CommentForm from './CommentForm'
import ClickOutside from '@/directives/click-outside'
import { onlyDeleteSelection } from '@/utils/CorrectionUtils'
import { nextTick } from 'vue'
// import { nextTick } from 'vue'

export default {
  name: 'TextEditorTooltip',
  components: {
    CorrectionForm,
    CommentForm
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      g: this.$store.state.g,
      isShow: false,
      isOutside: false,
    }
  },
  methods: {
    stop(e) {
      // stop event for disable editor mouseup handler
      e.stopPropagation()
    },
    recreatePopper (el) {
      if (this.g.popper && this.g.popper.destroy) {
        this.g.popper.destroy()
      }
      this.g.popper = createPopper(el, this.$refs.text_editor_tooltip, {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              bondary: this.g.editorElement,
              altBoundary: true,
            },
          },
        ],
      })
    },
    setType (type) {
      this.g.correction.type = type

      // add green selection
      const correctionSpan = this.g.editorElement.querySelector('#' + this.g.correction.id)
      correctionSpan.classList.add('correction')

      // mark comments with a custom tag
      if (type === 'comment') {
        correctionSpan.classList.add('tag_comment')
      }

      // need sync() after adding class to <span>
      // TODO: check why and how refactor it
      console.log('TextEditorTooltip', 'setType')
      this.$store.dispatch('updateTexts')

      nextTick(() => {
        const areas = document.getElementsByClassName('area_for_text')
        if (areas.length) {
          areas[0].focus()
        }
      })

      this.recreatePopper(this.g.correctionElement)
    },
    close() {
      this.g.popper.destroy()
      this.isShow = false
    },
    cancel () {
      // delete only if correction no created (isNew = true)
      if (this.g.correction.isNew) {
        onlyDeleteSelection(this.g.editorElement, this.g.correction.id, this.g.correction.oldHtml)
        this.g.correction.isNew = false
      }
      this.close()
      this.g.correctionElement = null
    },
    closeByOutside (e) {
      if (e.classList && e.classList.contains('correction')) {
        // no hide tooltip when me click corection for edit
        return
      }

      if (this.isOutside) {
        this.cancel()
      }
    },
  },
  computed: {
    correctionElement () {
      return this.g.correctionElement
    }
  },
  watch: {
    correctionElement (newValue) {
      if (newValue) {
        this.isShow = true
        this.recreatePopper(this.g.correctionElement)
        this.isOutside = false
        // TODO: Rewrite this (mousedown .. long time .. mouseup -> hide)
        setTimeout(() => { this.isOutside = true }, 500)
      } else {
        this.close()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text-editor-tooltip {
  // border: 1px solid #a8a3a3;
  padding: 20px;

  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  border-top: 1px solid #a8a3a3;
  border-left: 1px solid #a8a3a3;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.text-editor-tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -5px;
}

.text-editor-tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -5px;
  left: calc(50% - 3px);
}

.text-editor-tooltip[data-popper-placement^='left'] > #arrow {
  right: -5px;
}

.text-editor-tooltip[data-popper-placement^='right'] > #arrow {
  left: -5px;
}
.minus_pad {
  margin: -20px;
}
.btn-item {
  width:160px;
  border:0;
  z-index:100;
  position: relative;
  // outline:1px solid red;
}

.btn-item:hover {
  background: #ABD4FE;
}

//.btn-item:first-child {
//  margin-top:-30px;
//  margin-bottom: -8px;
//}
//.btn-item:last-child {
//  margin-bottom:-22px;
//}
</style>
