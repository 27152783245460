<template>
  <div
    ref="reader"
    class="text-editor text-editor-height"
    @mousedown="mousedown"
  ></div>
</template>

<script>
export default {
  name: 'TextReaderMobile',
  mounted() {
    this.$store.state.g.readerElement = this.$refs.reader
  },
  methods: {
    mousedown (e) {
      const id = e.target.id
      this.$emit('crn', id)
    },
  },
}
</script>

<style scoped lang="scss">
.text-editor {
  line-height: 30px;
  overflow-y: auto;
  padding-right: 16px;
  outline: none !important;
}

.text-editor-height {
  height: calc(100vh - 10rem);
}
</style>

<style>
[contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: gray;
}

.correction {
  display: inline;
  border-radius: 5px;
  user-select: none !important;
  cursor: pointer;
}

.correction {
  padding: 3px 0px;
  background: rgba(0, 255, 0, 0.2);
}

.correction .correction {
  padding: 0px 0px;
  /*background: rgba(0, 26, 255, 0.2);*/
}

</style>
