<template>
  <div
    ref="reader"
    class="text-editor text-editor-height"
    v-html="value"
    @mousedown="mousedown"
  ></div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'TextReader',
  props: {
    value: {
      type: String,
      required: true
    },
  },
  computed: {
    // TODO: rename g, d and other alpabet
    ...mapState(['g'])
  },
  methods: {
    ...mapMutations(['setReaderElement']),
    mousedown(e) {
      const id = e.target.id
      this.$emit('crn', id)

      const correction = this.g.corrections.find(_ => _.id === id)
      if (correction) {
        this.g.correction = correction
        this.g.correctionElement = this.$refs.reader.querySelector(
          '#' + this.g.correction.id
        )
      } else {
        console.log('correction not found!')
      }
    }
  },
  mounted() {
    this.setReaderElement(this.$refs.reader)
  }
}
</script>

<style scoped lang="scss">
.text-editor {
  line-height: 30px;
  overflow-y: auto;
  padding-right: 16px;
  outline: none !important;
}

.text-editor-height {
  height: calc(100vh - 240px);
}
</style>

<style>
[contenteditable='true']:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: gray;
}

.correction {
  display: inline;
  border-radius: 5px;
  user-select: none !important;
  cursor: pointer;
}

.correction {
  padding: 3px 0px;
  background: rgba(0, 255, 0, 0.2);
}

.correction .correction {
  padding: 0px 0px;
}
</style>
