<template>
  <div :class="{vee_error_border:error}">
    <div><slot></slot></div>
    <transition name="vee-form-error">
      <div v-if="error" class="vee-error-text">{{ error }}</div>
    </transition>
    <div class="vee-help-text">{{ help }}</div>
  </div>
</template>

<script>
export default {
  name: 'RedBorder',
  props: {
    help: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    }
  }
}
</script>

<style scoped>

/*.vee_error_border input {*/
/*  border: 1px solid #ff4d4f;*/
/*  outline: 0;*/
/*}*/

/*.vee_error_border input:focus {*/
/*  border: 1px solid #ff4d4f;*/
/*  outline: 0;*/
/*  box-shadow: 0 0 0 2px rgba(245, 34, 45, .2);*/
/*}*/

.vee-help-text, .vee-error-text {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 3px;

  position: absolute;
}

.vee-help-text {
  color: rgba(0, 0, 0, 0.45);
}

.vee-error-text {
  color: #f5222d;
}

.vee-error-text + .vee-help-text {
  display: none;
}

.vee-form-error-enter-active,
.vee-form-error-leave-active {
  transition: all 0.3s ease;
  /*transition: transform, opacity 0.3s ease;*/
}

.vee-form-error-enter,
.vee-form-error-leave-to {
  transform: translateY(-5px);
  opacity: 0;
}

</style>
