<template>
  <div ref="modalRoot" class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="example mb-3">
          Ich stehe um sieben Uhr auf.
          <span class="tag_word_order">Die</span> Unterricht an
          <span class="tag_missed_word"><span class="red-selection">dir Universitat begint</span></span> um halb
          neun. Punkt sieben klingelt der Wecker. Manchmal weckt mich meine
          <span class="tag_punctuation">Muter,</span> Ich springe sofort aus
        </div>
        <div class="instruction">
          <div class="title mb-2">Сlick on the highlight</div>
          <p class="mb-4">
            Find out what correction or comment your teacher has made in your
            text
          </p>
          <button type="button" class="btn btn-link p-0 mt-1 mb-4" @click="handleClick">
            Ok, Got It!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
  name: 'StudentsModal',
  data() {
    return {
      modal: undefined
    }
  },
  mounted() {
    console.log(this.$refs.modalRoot)
    this.modal = new Modal(this.$refs.modalRoot, {
      backdrop: 'static',
      keyboard: false
    })

    this.modal.show()
  },
  methods: {
    handleClick() {
      localStorage.setItem('instructionsShowed', 1)
      this.modal.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  border-width: 0px;
}

.modal-dialog {
  width: 328px;
  margin: auto;
}

.instruction {
  text-align: center;
  padding-left: 32px;
  padding-right: 32px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.red-selection {
  padding: 3px;
  border: 1px solid #FFA6AC;
  border-radius: 2px;
  background: rgba(250, 135, 150, 0.15)
}

.example {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #f0f5fb;
  padding: 31px 30px;
  height: 191px;
}
</style>
