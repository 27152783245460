<template>
  <aside class="aside">
    <transition name="fade" mode="out-in">
      <div v-if="textMode === 'original_text'">
        <SideMessage
          v-if="showWellDone && !corrections.length"
          title="Well done!"
          :closeable="true"
          class="mb-3"
          @close="showWellDone = false"
        >
          <div class="mt-2">
            You can select a word or part of the text to highlight what's wrong
            to a student, and make a correction or a comment
            <hr class="hr" />
            When you are done with the edits, save the text and share the link
          </div>
        </SideMessage>
        <CorrectionList />
      </div>
      <div v-else>
        <SideMessage title="View only mode" type="warn" class="mb-3">
          <div class="mt-2">
            You can’t make any changes in this mode. To create another
            correction – come back to the Original Text mode.
          </div>
        </SideMessage>
      </div>
    </transition>
  </aside>
</template>

<script>
import SideMessage from './SideMessage'
import CorrectionList from './CorrectionList'
import { mapState } from 'vuex'

export default {
  name: 'SideBar',
  props: {
    textMode: String
  },
  components: {
    CorrectionList,
    SideMessage
  },
  data() {
    return {
      showWellDone: true
    }
  },
  computed: {
    ...mapState({
      corrections: state => state.g.corrections
    })
  },
  watch: {
    corrections(newVal) {
      if (newVal.length) {
        this.showWellDone = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.aside {
  width: 432px;
  padding-left: 32px;
  padding-bottom: 32px;
  flex-shrink: 0;
}
</style>
