
function applyCorrections(wrapper, corrections) {
  corrections.forEach((crn) => {
    const el = wrapper.querySelector('#' + crn.id)
    if (el && crn.type === 'correction') {
      el.innerHTML = crn.newHtml
    }
  })
}

function deleteCorrection (
  editorElement,
  correctionId,
  correctionOldHtml,
) {
  const correctionElement = editorElement.querySelector('#' + correctionId)
  if (!correctionElement) {
    console.log('correction element not found, del impossible')
    return false
  }
  const correctionInnerHtml = correctionElement.innerHTML
  if (!correctionInnerHtml) {
    console.log('correction not found, deleting impossible')
    return false
  }

  // if (correctionInnerHtml.indexOf('<span') !== -1) {
  //   console.log('correction has another correction, deleting impossible')
  //   return false
  // }

  editorElement.innerHTML = editorElement.innerHTML
    .replace(new RegExp('<span\\s+id="' + correctionId + '"[^>]*>'), '<span>')
    .replace('<span>' + correctionInnerHtml + '</span>', correctionOldHtml)

  return true
}

function deleteCorrectionInHtml (html, correctionId, correctionOldHtml) {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html
  deleteCorrection(tempDiv, correctionId, correctionOldHtml)
  return tempDiv.innerHTML
}

function onlyDeleteSelection (editorElement, correctionId) {
  const correctionElement = editorElement.querySelector('#' + correctionId)
  if (!correctionElement) {
    console.log('onlyDeleteSelection, orrectionElement not found #' + correctionId)
    return false
  }
  const correctionInnerHtml = correctionElement.innerHTML
  if (!correctionInnerHtml) {
    console.log('correction not found, deleting impossible')
    return false
  }
  editorElement.innerHTML = editorElement.innerHTML
    .replace(new RegExp('<span\\s+id="' + correctionId + '"[^>]*>'), '<span>')
    .replace('<span>' + correctionInnerHtml + '</span>', correctionInnerHtml)
  return true
}

export { deleteCorrection, deleteCorrectionInHtml, onlyDeleteSelection, applyCorrections }
