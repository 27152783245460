<template>
  <div>
    Grade this text:<br>
    <div class="form-check form-check-inline" v-for="item in itemsWithId" :key="item.id">
      <input
        class="a-input form-check-input"
        type="radio"
        :id="item.id"
        :checked="item.label == modelValue"
        @input="$emit('update:modelValue', item.label)"
      >
      <label class="a-label form-check-label" :for="item.id">{{ item.label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Grade',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: ''
    },
  },
  emits: ['update:modelValue'],
  data () {
    return {
      items: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
    }
  },
  computed: {
    itemsWithId () {
      return this.items.map((item) => ({
        id: `grd_${item}`,
        label: item,
      }))
    }
  }
}
</script>

<style scoped>
.a-wrap {
  /*border: 1px solid #ced4da;*/
  /*border-radius: 4px;*/
}
.a-input {
  display: none;
}
.a-label {
  width: 100%;
  text-align: center;
  padding: 7px 12px;
  background: #F0F4F9;
  cursor: pointer;
}

.form-check-inline:first-child .a-label {
  border-radius: 4px 0 0 4px;
}
.form-check-inline:last-child .a-label {
  border-radius: 0 4px 4px 0;
}

.form-check-inline:first-child {
  border-radius: 4px 0 0 4px;
}
.form-check-inline:last-child {
  border-radius: 0 4px 4px 0;
}

.a-input:checked + .a-label {
  color: white;
  background: #258FFB;
}
.form-check-inline {
  width: 40px;
  padding-left: 0;
  margin-bottom: 0;
  margin-right: 1px;
}
</style>
