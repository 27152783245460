function getHTMLOfSelection () {
  var range
  if (document.selection && document.selection.createRange) {
    range = document.selection.createRange()
    return range.htmlText
  } else if (window.getSelection) {
    var selection = window.getSelection()
    if (selection.rangeCount > 0) {
      range = selection.getRangeAt(0)
      var clonedSelection = range.cloneContents()
      var div = document.createElement('div')
      div.appendChild(clonedSelection)
      return div.innerHTML
    } else {
      return ''
    }
  } else {
    return ''
  }
}

function replaceSelectedText(replacementText) {
  var sel, range
  if (window.getSelection) {
    sel = window.getSelection()
    if (sel.rangeCount) {
      range = sel.getRangeAt(0)
      range.deleteContents()
      range.insertNode(document.createTextNode(replacementText))
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange()
    range.text = replacementText
  }
}

function replaceSelectionWithHtml(html) {
  var range
  if (window.getSelection && window.getSelection().getRangeAt) {
    range = window.getSelection().getRangeAt(0)
    range.deleteContents()
    var div = document.createElement('div')
    div.innerHTML = html
    var frag = document.createDocumentFragment(); var child
    while ((child = div.firstChild)) {
      frag.appendChild(child)
    }
    range.insertNode(frag)
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange()
    range.pasteHTML(html)
  }
}

export { getHTMLOfSelection, replaceSelectedText, replaceSelectionWithHtml }
