<template>
  <div class="correction-form">
    <span class="title me-2">CORRECTION</span>
    <TagList :value="g.correction.tags" @tag="removeTag" />
    <div v-if="isReadonly" class="mt-3">
      <span v-html="g.correction.oldHtml" class="incorrect-text"></span>
      →
      <span v-html="g.correction.newHtml"></span>
    </div>
    <div v-else class="mt-3">
      <TagList
        :value="g.correction.tags"
        @tag="addTag"
        excludeValue
        class="mb-3"
      />
      <div
        ref="editor"
        class="correction-text area_for_text"
        contenteditable="true"
        spellcheck="false"
        placeholder="Write the corrected text here"
        v-html="g.correction.newHtml"
      ></div>
      <hr />
      <div v-if="!isComment" class="add-comment" @click="toggle">
        + Add Comment
      </div>
      <div v-else class="d-flex flex-wrap">
        <div class="title mb-3">COMMENT</div>
        <textarea
          rows="1"
          v-model="g.correction.comment"
          ref="comment"
          placeholder="Write your comment here"
          class="comment-area mb-3"
          @input="mixin_autoResize_resize"
        ></textarea>
      </div>
      <button class="btn btn-primary" @click="save" :disabled="!isEnabled">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import TagList from './TagList'
import AutoResize from '../mixins/AutoResize'

export default {
  name: 'CorrectionForm',
  mixins: [AutoResize],
  components: {
    TagList
  },
  props: {
    isReadonly: Boolean
  },
  data() {
    return {
      g: this.$store.state.g,
      isComment: false
    }
  },
  computed: {
    isEnabled() {
      return this.g.correction.tags?.length > 0
    }
  },
  methods: {
    toggle() {
      this.isComment = !this.isComment
      if (this.isComment) {
        setTimeout(() => {
          this.$refs.comment.focus()
        }, 0)
      }
    },
    save() {
      // from editor to correction object
      this.g.correction.newHtml = this.$refs.editor.innerHTML.replaceAll(
        '&nbsp;',
        ' '
      )

      // delete if exists
      this.g.corrections = this.g.corrections
        .filter(correction => correction.id !== this.g.correction.id)
        .sort((a, b) => {
          if (a.uid < b.uid) return -1
          else if (a.uid > b.uid) return 1
          return 0
        })

      // TODO: vuex action or fully clean component
      this.g.correction.isNew = false // no delete on close tooltip
      this.g.corrections.push(this.g.correction)
      this.g.correctionElement = null
      // this.g.popper.destroy()
      this.$emit('close')

      console.log('CorrectionForm', 'save')
      this.$store.dispatch('updateTexts')
    },
    setCorrectionColorByTag() {
      // TODO !!! check this logic
      if (this.g.correction.tags.length) {
        this.g.correctionElement.classList.forEach(_class => {
          if (_class.startsWith('tag_')) {
            this.g.correctionElement.classList.remove(_class)
          }
        })
        this.g.correctionElement.classList.add(
          'tag_' + this.g.correction.tags[0]
        )
      }
    },
    addTag(tag) {
      this.g.correction.tags.push(tag)
      this.setCorrectionColorByTag()
    },
    removeTag(tag) {
      const index = this.g.correction.tags.indexOf(tag)
      if (index !== -1) {
        this.g.correction.tags.splice(index, 1)
      }
      this.setCorrectionColorByTag()
    }
  }
}
</script>

<style lang="scss" scoped>
.correction-form {
  width: 423px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #7d7d7d;
}

.correction-text {
  min-height: 26px;
  line-height: 26px;
  outline: none;
}

.add-comment {
  margin-bottom: 16px;
  font-size: 16px;
  color: #007bff;
  cursor: pointer;
}

.comment-area {
  line-height: 26px;
  width: 100%;
  font-size: 16px;
  padding: 0px;
  border: none;
  outline: none;

  ::placeholder {
    color: #383838;
  }
}

.incorrect-text {
  text-decoration: line-through;
}
</style>
