export default {
  beforeMount (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // if vue remove element from DOM after click,
      // document.body.contains(event.target) fixed this
      if (!(el === event.target || el.contains(event.target)) && document.body.contains(event.target)) {
        binding.value(event, el)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}
