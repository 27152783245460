<template>
  <span class="tags-wrapper">
    <span
      v-for="tag in colorized"
      :key="tag.id"
      class="tag"
      :style="{ color: tag.color, backgroundColor: tag.bgColor }"
      @click="$emit('tag', tag.id)"
      >{{ tag.title }}</span
    >
  </span>
</template>

<script>
export default {
  name: 'TagList',
  props: {
    value: {
      type: Array,
      required: true
    },
    excludeValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['tag'],
  data() {
    return {
      tags: [
        {
          id: 'spelling',
          title: 'Spelling',
          color: '#6993BD',
          bgColor: '#ABD4FE'
        },
        {
          id: 'grammar',
          title: 'Grammar',
          color: '#BC8185',
          bgColor: '#FFC6C9'
        },
        {
          id: 'missed_word',
          title: 'Missed word',
          color: '#B8AA91',
          bgColor: '#FFEECE'
        },
        {
          id: 'punctuation',
          title: 'Punctuation',
          color: '#908BB6',
          bgColor: '#CCC6FF'
        },
        {
          id: 'vocabulary',
          title: 'Vocabulary',
          color: '#A8ADC5',
          bgColor: '#E1E5F9'
        },
        {
          id: 'word_order',
          title: 'Word order',
          color: '#95B9A6',
          bgColor: '#D8F3E5'
        },
        {
          id: 'writing_issues',
          title: 'Writing issues',
          color: '#A4ADB9',
          bgColor: '#D9DFE7'
        },
        {
          id: 'verb_position',
          title: 'Verb Position',
          color: '#aca168',
          bgColor: '#F5F297'
        }
      ]
    }
  },
  computed: {
    colorized() {
      if (this.excludeValue) {
        return this.tags.filter(tag => this.value.indexOf(tag.id) === -1)
      } else {
        return this.value.map(tag => {
          return this.tags.find(_tag => _tag.id === tag)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tags-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  line-height: 26px;
  padding: 0px 4px;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
}
</style>
