<template>
  <div class="container d-flex justify-content-center flex-column h-100">
    <div class="row">
      <div class="col">
        <div class="d-flex flex-column align-items-center content-shift">
          <img
            src="../assets/images/Done.svg"
            alt="Done"
            class="done-image mb-4"
          />
          <h1 class="title mb-4 text-center">
            Great! You can share this text <br />with the student
          </h1>
          <div class="d-flex flex-column mt-3">
            <label for="student-email" class="mb-1"
              >Share this text via email:</label
            >
            <div class="d-flex mb-4">
              <input
                id="student-email"
                type="email"
                class="input share-input me-2"
                placeholder="Email"
                v-model="studentEmail"
              />
              <button
                class="btn btn-primary btn-publish"
                :disabled="!isEmailValid"
                @click.once="handlePublish"
              >
                <span
                  v-if="publishInProgress"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-else>Publish</span>
              </button>
            </div>
            <transition name="fade">
              <div class="appear-block">
                <div v-if="shareUrl">
                  <div class="text-center">
                    <div class="mt-2 mb-1">Now you can share this link:</div>
                    <CopyLink :url="shareUrl" class="mb-4" />
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CopyLink from '../components/CopyLink'
import api from '../utils/api'
import validateEmail from '../utils/validateEmail'

export default {
  name: 'share',
  layout: 'default',
  components: { CopyLink },
  data() {
    return {
      shareUrl: '',
      publishInProgress: false
    }
  },
  computed: {
    ...mapState({
      studentGroupName: state => state?.query?.studentGroupName
    }),
    studentEmail: {
      get() {
        return this.$store.state?.query?.studentEmail
      },
      set(value) {
        this.$store.commit('setStudentEmail', value)
      }
    },
    isEmailValid() {
      return validateEmail(this.studentEmail.trim())
    }
  },
  methods: {
    ...mapMutations(['setPublished', 'clearState']),
    async handlePublish() {
      this.publishInProgress = true
      const query = this.$store.state.query

      try {
        const { data } = await api.post('/publications', query)
        this.shareUrl = `${window.location.origin}/${data.token}`
      } catch (e) {
        console.error(e)
      }

      this.publishInProgress = false
      this.setPublished()
    }
  }
}
</script>

<style lang="scss" scoped>
.content-shift {
  margin-top: -20%;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #323232;
  margin: 0px;
}

.done-image {
  width: 40px;
  height: 40px;
}

.share-input {
  width: 380px;
  text-indent: 12px;
}

.btn-publish {
  min-width: 82px;
}

.appear-block {
  min-height: 126px;
}
</style>
