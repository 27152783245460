<template>
  <div
    ref="editor"
    class="text-editor text-editor-height"
    :class="{ hideCaret: hasCorrections }"
    spellcheck="false"
    placeholder="Enter text here..."
  ></div>
</template>

<script>
import uniqId from '@/utils/uniqId'
import {
  getHTMLOfSelection,
  replaceSelectionWithHtml
} from '@/utils/getHTMLOfSelection'
import { onlyDeleteSelection } from '@/utils/CorrectionUtils'
import { mapMutations } from 'vuex'

export default {
  name: 'TextEditor',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    height1: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    disableEditAfterFirstCorrection: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      g: this.$store.state.g
    }
  },
  computed: {
    hasCorrections() {
      return this.g.corrections.length > 0
    }
  },
  mounted() {
    this.$refs.editor.innerHTML = this.modelValue
    this.$refs.editor.addEventListener('mousedown', this.mousedown)
    window.addEventListener('mouseup', this.mouseup)

    this.$refs.editor.addEventListener('input', this.stop)
    this.$refs.editor.addEventListener('keydown', this.keydown)
    this.$refs.editor.addEventListener('keyup', this.stop)
    this.$refs.editor.addEventListener('cut', this.stop)
    this.$refs.editor.addEventListener('paste', this.pasteHandler)
    this.$refs.editor.addEventListener('drop', this.pasteHandler)
    this.$refs.editor.addEventListener('selectstart', this.selectStartHandler)

    this.$refs.editor.setAttribute('contenteditable', this.editable)

    this.setEditorElement(this.$refs.editor)
  },
  unmounted() {
    window.removeEventListener('mouseup', this.mouseup)
  },
  methods: {
    ...mapMutations(['setEditorElement']),
    keydown(e) {
      // use <br> on press enter key (else browser adding <div></div>
      if (e.keyCode === 13) {
        e.preventDefault()

        // div for optimize mac
        document.execCommand('insertHTML', false, '<br><div></div>')
      }
      this.stop(e)
    },
    stop(e) {
      if (this.disableEditAfterFirstCorrection && this.hasCorrections) {
        e.preventDefault()
      }
      // console.log('stop', 'update:modelValue', this.$refs.editor.innerHTML)
      this.$emit('update:modelValue', this.$refs.editor.innerHTML)
    },
    pasteHandler(e) {
      e.preventDefault()

      if (this.disableEditAfterFirstCorrection && !this.hasCorrections) {
        // get text representation of clipboard
        let text = (e.originalEvent || e).clipboardData.getData('text/plain')
        text = text.trim()
        text = text.replace(/\n\s*\n/g, '\n')
        text = text.replaceAll('\n', '<br>\n')

        // insert text manually
        document.execCommand('insertHTML', false, text)
        this.$emit('update:modelValue', this.$refs.editor.innerHTML)
      }
    },

    selectStartHandler(e) {
      // Prevent selection on correction
      // Not possible to do it by css because of user-select issue in chrome
      const isStartedOnCorrection = (e.target?.tagName,
      e.target?.parentNode?.tagName).includes('SPAN')
      if (isStartedOnCorrection) {
        e.preventDefault()
      }
    },

    mousedown(e) {
      const id = e.target.id

      // if desktop editor
      if (this.disableEditAfterFirstCorrection) {
        // remove last selection on show exists correction
        if (this.g.correction.isNew) {
          onlyDeleteSelection(
            this.$refs.editor,
            this.g.correction.id,
            this.g.correction.oldHtml
          )
          this.g.correction.isNew = false
        }

        const correction = this.g.corrections.find((_) => _.id === id)
        if (correction) {
          this.g.correction = correction
          this.g.correctionElement = this.$refs.editor.querySelector(
            '#' + this.g.correction.id
          )
        } else {
          console.log('correction not found!')
        }
      }
    },

    mouseup() {
      const selection = window.getSelection()

      // anchorNode not in editor
      if (!this.$refs.editor.contains(selection.anchorNode)) {
        return
      }

      // focusNode not in editor
      if (!this.$refs.editor.contains(selection.focusNode)) {
        return
      }

      // //right whitespace
      // if (/\s$/.test(htmlOfSelection)) {
      //   this.$refs.editor.selectionEnd -= 1;
      //   htmlOfSelection = getHTMLOfSelection().replaceAll('&nbsp;', ' ')
      // }
      // //left whitespace
      // if (/\s$/.test(htmlOfSelection)) {
      //   this.$refs.editor.selectionStart += 1;
      //   htmlOfSelection = getHTMLOfSelection().replaceAll('&nbsp;', ' ')
      // }

      // Hadle case of selection ending on correction.
      // No need to handle start on correction, it prevented by selectStartHandler()
      const { anchorNode, focusNode } = selection
      if (focusNode?.parentNode?.tagName === 'SPAN') {
        const currentRange = selection.getRangeAt(0)

        if (anchorNode.parentNode === focusNode.parentNode) {
          currentRange.selectNode(
            getTopCorrectionNode(this.$refs.editor, focusNode.parentNode)
          )
        } else {
          const nodeToSelect = getTopCorrectionNode(
            this.$refs.editor,
            focusNode
          )

          // Check range direction (start node is always left of selection)
          // It ignore "left to right" or "right to left", so start node is always left node.
          if (currentRange.startContainer.parentNode === this.$refs.editor) {
            currentRange.setEndAfter(nodeToSelect)
          } else {
            currentRange.setStartBefore(nodeToSelect)
          }
        }

        // eslint-disable-next-line no-inner-declarations
        function getTopCorrectionNode(selectionRootNode, correctionNode) {
          if (selectionRootNode === correctionNode.parentNode) {
            return correctionNode
          } else {
            return getTopCorrectionNode(
              selectionRootNode,
              correctionNode.parentNode
            )
          }
        }
      }

      const htmlOfSelection = getHTMLOfSelection().replaceAll('&nbsp;', ' ')

      if (htmlOfSelection.trim() === '') {
        return
      }

      // before create new, we need delete current
      if (this.g.correction.isNew) {
        this.g.correction.isNew = false
        const oldCorrection = this.g.correction
        setTimeout(() => {
          onlyDeleteSelection(
            this.g.editorElement,
            oldCorrection.id,
            oldCorrection.oldHtml
          )
          this.g.correctionElement = document.getElementById(
            this.g.correction.id
          ) // fix correctionElement
        }, 50)
      }

      const uid = uniqId()
      const id = 'crn_' + uid
      this.g.correction = {
        isNew: true,
        type: '',
        uid: uid,
        id: id,
        oldHtml: htmlOfSelection,
        newHtml: htmlOfSelection,
        fullHtml: '<span id="' + id + '">' + htmlOfSelection + '</span>',
        tags: [],
        comment: ''
      }

      // this.contenteditable = false
      this.$refs.editor.setAttribute('contenteditable', false)
      replaceSelectionWithHtml(this.g.correction.fullHtml)
      // this.contenteditable = true
      this.$refs.editor.setAttribute('contenteditable', true)

      this.g.correctionElement = document.getElementById(this.g.correction.id)

      // update footer
      // console.log('mouseup', 'update:modelValue', this.$refs.editor.innerHTML)
      this.$emit('update:modelValue', this.$refs.editor.innerHTML)
    }
  }
}
</script>

<style scoped lang="scss">
.text-editor {
  line-height: 30px;
  overflow-y: auto;
  padding-right: 16px;
  outline: none !important;
  // font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.text-editor-height {
  height: calc(100vh - 240px);
}

.hideCaret {
  caret-color: transparent !important;
}
</style>

<style>
[contenteditable='true']:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: gray;
}

.correction {
  display: inline;
  border-radius: 5px;
  user-select: none !important;
  cursor: pointer;
}

.correction {
  padding: 3px 0px;
  background: rgba(0, 255, 0, 0.2);
}

.correction .correction {
  padding: 0px 0px;
  /*background: rgba(0, 26, 255, 0.2);*/
}

/*.correction .correction .correction {*/
/*  padding: 0px 0px;*/
/*  !*background: rgba(255, 0, 81, 0.2);*!*/
/*}*/
</style>
