<template>
  <div class="correction-list">
    <template v-for="correction in corrections" :key="correction.uid">
      <CorrectionItem
        class="correction-item"
        :correction="correction"
        @editThis="editThis(correction)"
        @deleteThis="deleteThis(correction)"
      ></CorrectionItem>
    </template>
  </div>
</template>

<script>
import CorrectionItem from './CorrectionItem'
import { deleteCorrection, deleteCorrectionInHtml } from '@/utils/CorrectionUtils'
import { asyncBootstrapModal } from './modals/ConfirmModal.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'CorrectionList',
  components: {
    CorrectionItem,
  },
  data () {
    return {
      g: this.$store.state.g,
    }
  },
  computed: {
    corrections () {
      return [...this.$store.state.g.corrections].sort((a, b) => {
        if (a.uid < b.uid) return -1
        else if (a.uid > b.uid) return 1
        return 0
      })
    }
  },
  methods: {
    ...mapMutations(['setCorrections']),
    ...mapActions(['updateTexts']),
    editThis (correction) {
      // correction.isNew = false
      this.g.correction = correction
      this.g.correctionElement = this.g.editorElement.querySelector('#' + this.g.correction.id)
    },
    async deleteThis (correction) {
      const confirmResult = await asyncBootstrapModal('confirmDelete')

      if (!confirmResult) {
        return
      }
      // const index = this.corrections.find(c => c.uid === correction.uid)
      const index = this.corrections.indexOf(correction)
      if (index !== -1) {
        this.corrections.splice(index, 1)

        // comment not have oldHtml
        let oldHtml = correction.oldHtml
        if (correction.type === 'comment') {
          oldHtml = this.g.editorElement.querySelector('#' + correction.id).innerHTML
        }

        // delete in other outer oldHtml
        this.corrections.forEach((otherCorrection) => {
          otherCorrection.oldHtml = deleteCorrectionInHtml(
            otherCorrection.oldHtml,
            correction.id,
            oldHtml
          )
          otherCorrection.newHtml = deleteCorrectionInHtml(
            otherCorrection.newHtml,
            correction.id,
            oldHtml
          )
        })

        deleteCorrection(this.g.editorElement, correction.id, oldHtml)
        this.setCorrections(this.corrections.filter(c => c.uid !== correction.uid))
        this.updateTexts()
      }
    }
  }
}
</script>

<style scoped>
.correction-list {
  max-height: calc(100vh - 240px);
  padding-bottom: 22px;
  overflow-y: auto;
}

.correction-item {
  padding: 12px 20px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 8px 8px;
  cursor: pointer;
}
</style>
