<template>
  <span>
    <span class="removed">{{ oldText }}</span>
    →
    <template v-for="part in diffChars" :key="part">
      <span
        v-if="!part.removed"
        :class="{ added: part.added }"
        >{{ part.value }}</span
      >
    </template>
  </span>
</template>

<script>
import * as Diff from 'diff'

export default {
  name: 'CorrectionDiff',
  props: {
    oldHtml: {
      type: String,
      required: true
    },
    newHtml: {
      type: String,
      required: true
    }
  },
  computed: {
    oldText() {
      return this.withoutHtml(this.oldHtml)
    },
    newText() {
      return this.withoutHtml(this.newHtml)
    },
    diffChars() {
      return Diff.diffChars(this.oldText, this.newText)
    }
  },
  methods: {
    withoutHtml(value) {
      return value.replace(/(<([^>]+)>)/gi, '')
    }
  }
}
</script>

<style scoped>
/* .added {
  color: #59cd8f;
} */

.removed {
  text-decoration: line-through;
}
</style>
