<template>
  <header>
    <div class="logo"></div>
    <div class="buttons"></div>
  </header>
</template>

<script>
export default {
  name: 'HeaderMobile'
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.logo {
  /*color: #258FFB;*/
  /*font-size: 24px;*/
  /*font-weight: bold;*/
  /*text-transform: uppercase;*/

  background-image: url("~@/assets/images/logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 160px;
  height: 24px;
  margin-top: 5px;
}
</style>
