<template>
  <div>
    Summary for the student<br>
    <textarea
      class="form-control w"
      placeholder="Write smth about this text"
      v-bind="$attrs"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'Notes',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: ''
    },
  },
  emits: ['update:modelValue'],
}
</script>

<style scoped>
.w {
  width: 470px;
  height: 220px;
}
</style>
