<template>
  <div>
    <div class="copyLink" @click="copy(url)">
      <div class="icon"></div>
      <span v-if="!isCopied">Copy link</span>
      <span v-else>Link copied!</span>
    </div>
    <input ref="hiddenInput" type="hidden" :value="url" />
  </div>
</template>

<script>
export default {
  name: 'CopyLink',
  data() {
    return {
      isCopied: false
    }
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  methods: {
    async copy(str) {
      this.$refs.hiddenInput.setAttribute('type', 'text')
      this.$refs.hiddenInput.select()

      const successful = document.execCommand('copy')

      if (!successful) {
        console.error('Copy error!')
      }

      /* unselect the range */
      this.$refs.hiddenInput.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()

      this.isCopied = true
    }
  }
}
</script>

<style scoped>
.copyLink {
  display: inline-flex;
  align-items: center;
  color: #258ffb;
  font-size: 16px;
  cursor: pointer;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/images/link_2x.png');
  background-size: contain;
  margin-right: 6px;
}
</style>
