// Validate values with rules

class VeeFormValidation {
  constructor(values, rules, callback, realtimeWatch = false) {
    this.values = {}
    // this.rules = rules
    this.callback = callback
    this.realtimeWatch = realtimeWatch
    this.reset(values, rules)
  }

  async check(values, rules, force = false) {
    let isValid = true
    // eslint-disable-next-line no-restricted-syntax
    for (const key in values) {
      // console.log('check', this.values, values)

      if (!force && values[key] === this.values[key]) {
        // console.log('skip', key, values[key], this.values[key])
        // eslint-disable-next-line no-continue
        continue
      }

      this.values[key] = values[key]

      let inRule = true

      if (rules[key] instanceof Array) {
        let errorMessage = ''

        // eslint-disable-next-line no-restricted-syntax
        for (const rule of rules[key]) {
          if (inRule && rule.required &&
            (values[key] === '' ||
              values[key] === null ||
              values[key] === undefined)
          ) {
            console.log(values[key], key)

            errorMessage = rule.message
            inRule = false
          }

          if (inRule && typeof rule.minLength === 'number' && values[key].length < rule.minLength) {
            errorMessage = rule.message
            inRule = false
          }

          if (inRule && typeof rule.maxLength === 'number' && values[key].length > rule.maxLength) {
            errorMessage = rule.message
            inRule = false
          }

          if (inRule && rule.regExp instanceof String) {
            rule.regExp = new RegExp(rule.regExp)
          }

          if (inRule && rule.regExp instanceof RegExp && rule.regExp.test(values[key])) {
            errorMessage = rule.message
            inRule = false
          }

          if (inRule && rule.validator instanceof Function) {
            let result
            if (rule.async) {
              // eslint-disable-next-line no-await-in-loop
              result = await rule.validator(values[key])
            } else {
              result = rule.validator(values[key])
            }
            if (result && typeof result === 'string') {
              errorMessage = result
              inRule = false
            } else if (result === false) {
              errorMessage = rule.message
              inRule = false
            }
          }
        }
        if (!inRule) isValid = false
        this.callback(key, errorMessage)
      }
    }
    return isValid
  }

  forceCheck(values, rules) {
    const res = this.check(values, rules, true)
    this.realtimeWatch = true
    return res
  }

  watchCheck(values, rules) {
    if (this.realtimeWatch) {
      return this.check(values, rules)
    }
    return Promise.resolve('')
  }

  reset(values, rules) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in values) {
      if (rules[key] instanceof Array) {
        this.values[key] = values[key]
      }
      this.callback(key, '')
    }
    this.realtimeWatch = false
  }
}

export default VeeFormValidation // expose the VeeFormValidation class to other modules
