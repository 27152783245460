<template>
  <div style="margin-right: 16px; position: relative">
    <input
      type="text"
      class="form-control no-round-transition"
      :class="{ 'no-round-active': showHelper }"
      :placeholder="placeholder"
      v-bind="$attrs"
      v-model.trim="searchText"
      @focus="handleFocus"
      @blur="handleBlur"
    />
    <FormHelper
      :show="showHelper"
      :items="filteredItems"
      @select="handleSelect"
    />
  </div>
</template>

<script>
import FormHelper from './FormHelper'

export default {
  name: 'UiSelect',
  components: { FormHelper },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      searchText: this.modelValue,
      showHelper: false
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => {
        return item
          .toLowerCase()
          .includes(this.searchText.toLowerCase())
      })
    }
  },
  methods: {
    handleFocus() {
      if (this.searchText) {
        this.searchText = ''
      }

      this.showHelper = true
    },
    handleBlur() {
      if (!this.items.includes(this.modelValue)) {
        this.$emit('update:modelValue', '')
        this.searchText = ''
      }

      this.showHelper = false
    },
    handleSelect(value) {
      this.$emit('update:modelValue', value)
      this.searchText = value
      this.showHelper = false
    }
  }
}
</script>

<style scoped>
.no-round-transition {
  transition: border-radius 0.3s ease;
}
.no-round-active {
  border-radius: 0.25rem 0.25rem 0 0;
}
</style>
