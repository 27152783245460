<template>
  <div class="mobile-correcion">
    <div class="title">
      <span class="text-capitalize type">{{ correction.type }}</span>
      <TagList
        v-if="correction.type === 'correction'"
        :value="correction.tags"
      />
    </div>
    <div class="slider">
      <template v-if="correction.type === 'correction'">
        <div class="diff">
          <CorrectionDiff
            :oldHtml="correction.oldHtml"
            :newHtml="correction.newHtml"
          />
        </div>
        <div class="comment" v-if="correction.comment?.length">
          <div class="comment-label">Comment</div>
          {{ correction.comment }}
        </div>
      </template>
      <div v-else class="comment">
        {{ correction.comment }}
      </div>
    </div>
  </div>
</template>

<script>
import TagList from './TagList'
import CorrectionDiff from './CorrectionDiff'
export default {
  name: 'CorrectionItemMobile',
  components: { CorrectionDiff, TagList },
  props: {
    correction: {
      type: Object,
      required: true
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slider: false
    }
  }
}
</script>

<style scoped>
.diff {
  margin-top: 16px;
  margin-bottom: 16px;
}
.type {
  font-size: 14px;
  color: #7d7d7d;
  font-weight: 500;
  margin-right: 8px;
  letter-spacing: 0.045em;
}
.comment {
  margin-top: 8px;
}
.text-capitalize {
  text-transform: uppercase;
  letter-spacing: 0.045em;
}
.comment-label {
  border-top: 1px solid #d9d9d9;
  padding-top: 16px;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: #7d7d7d;
}
</style>
