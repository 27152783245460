import { createStore } from 'vuex'
import { applyCorrections } from '../utils/CorrectionUtils'

const teacherEmail = localStorage.getItem('teacherEmail') ?? ''
// TODO: should be removed after ~1 month
localStorage.removeItem('studentGroupNames')

const getDefaultState = () => ({
  query: {
    originalText: '',
    redactedText: '',
    finalText: '',
    grade: '',
    assignmentLevel: '',
    studentGroupName: '',
    assignmentTypeName: '',
    notes: '',
    studentEmail: '',
    teacherEmail
  },
  displayCorrection: null, // !!! TODO move to g
  g: {
    correction: {},
    corrections: [],
    editorElement: null,
    readerElement: null
  },
  isPublished: false
})

export default createStore({
  state: getDefaultState(),
  mutations: {
    setOriginalText(state, originalText) {
      state.query.originalText = originalText
    },
    setRedactedText(state, redactedText) {
      state.query.redactedText = redactedText
    },
    setEditorElement(state, editor) {
      state.g.editorElement = editor
    },
    setReaderElement(state, reader) {
      state.g.readerElement = reader
    },
    setDisplayCorrection(state, correction) {
      state.displayCorrection = correction
    },
    setStudentEmail(state, studentEmail) {
      state.query.studentEmail = studentEmail
    },
    setStudentGroupName(state, studentGroupName) {
      state.query.studentGroupName = studentGroupName
    },
    setAssignmentTypeName(state, assignmentTypeName) {
      state.query.assignmentTypeName = assignmentTypeName
    },
    setPublished(state) {
      state.isPublished = true
    },
    syncReaderAndEditor(state) {
      state.g.readerElement.innerHTML = state.g.editorElement?.innerHTML
    },
    clearState(state) {
      Object.assign(state, getDefaultState())
    },
    setCorrections(state, corrections) {
      state.g.corrections = corrections
    }
  },
  actions: {
    updateTexts({ commit, state }, value) {
      // TODO: still bad code
      commit('syncReaderAndEditor')

      applyCorrections(
        state.g.readerElement,
        state.g.corrections.sort((a, b) => b.uid - a.uid)
      )

      commit('setRedactedText', state.g.readerElement.innerHTML || '')
      commit('setOriginalText', state.g.editorElement.innerHTML || '')
      // commit('setOriginalText', value)
    }
  },
  modules: {
  }
})
