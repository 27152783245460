import { createRouter, createWebHistory } from 'vue-router'
import { createRouterLayout } from 'vue-router-layout'
import Index from '@/pages/index.vue'
import Grades from '@/pages/grades.vue'
import Share from '@/pages/share.vue'
import MobileView from '@/pages/mobile-view.vue'
import navigationGuard from '@/utils/navigationGuard.js'

const RouterLayout = createRouterLayout(layout => {
  return import('@/layouts/' + layout + '.vue')
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: [
        {
          path: '/',
          name: 'index',
          component: Index
        },
        {
          name: 'grades',
          path: '/grades',
          component: Grades
        },
        {
          name: 'share',
          path: '/share',
          component: Share
        }
      ]
    },
    {
      name: 'student',
      path: '/:token',
      component: MobileView
    }
  ]
})

router.beforeEach(navigationGuard)

export default router
