<template>
  <div class="vh-100 row p-0 m-0 justify-content-center">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
      <HeaderMobile class="mb-3" />
      <div>
        <TextModeChanger v-model="textMode" :isMobile="true" class="mb-3" />
        <TextReaderMobile @crn="crn" />
        <!-- <div class="space"></div> -->
      </div>
      <SlideUpForm />
      <StudentsModal v-if="showInstructionsModal" />
    </div>
  </div>
</template>

<script>
import HeaderMobile from '../components/HeaderMobile'
import SlideUpForm from '../components/SlideUpForm'
import TextModeChanger from '../components/controls/TextModeChanger'
import TextReaderMobile from '../components/TextReaderMobile'
import StudentsModal from '../components/modals/StudentsModal'
import api from '../utils/api'

export default {
  name: 'MobileView',
  components: {
    TextReaderMobile,
    TextModeChanger,
    SlideUpForm,
    HeaderMobile,
    StudentsModal
  },
  data() {
    return {
      d: {},
      textMode: 'original_text',
      showInstructionsModal: !localStorage.getItem('instructionsShowed')
    }
  },
  methods: {
    crn(id) {
      window.ym(85394716, 'reachGoal', 'crn_opening')
      const crn = this.d.remarks.find((crn) => crn.id === id)
      if (crn) {
        this.$store.state.displayCorrection = crn
      }
    }
  },
  watch: {
    textMode(newValue) {
      if (newValue === 'original_text') {
        this.$store.state.g.readerElement.innerHTML = this.d.originalText
        this.$store.state.displayCorrection = null
      } else {
        this.$store.state.g.readerElement.innerHTML = this.d.redactedText
        this.$store.state.displayCorrection = null
      }
    }
  },
  async created() {
    const token = this.$route.params.token
    console.log(token)
    try {
      const { data } = await api.get(`/publications?token=${token}`)
      console.log('Server response:', data)

      this.d = data[0]
      if (!this.d) {
        throw new Error('Publication not found')
      }

      this.$store.state.g.readerElement.innerHTML = this.d?.originalText
    } catch (e) {
      // TODO: alert to popup
      alert(`Server request issue: ${e.message}`)
      console.error(e)
    }
  }
}
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
}

.c1 {
  flex-grow: 1;
}

.c2 {
  flex-grow: 1;
  flex: 0 0 180px;
}

.space {
  height: 200px;
}
</style>
