<template>
  <div class="part-two">
    <div style="display: flex; justify-content: space-between; margin-top:24px">
      <div>
        <!--        {{ $store.state.query }}-->
        <RedBorder :error="errors.grade">
          <Grade v-model="$store.state.query.grade"></Grade>
        </RedBorder>
        <br />
        <Notes v-model="$store.state.query.notes"></Notes>
        <br />
      </div>
      <div>
        <div v-if="issuesAmount" class="issuses-box">
          <div class="issuses-title">
            <span class="blue">{{ issuesAmount }}</span> issues in this text:
          </div>
          <div v-if="nSpelling" class="issuses-row">
            <div class="name">Spelling</div>
            <div class="count">{{ nSpelling }}</div>
          </div>
          <div v-if="nGrammar" class="issuses-row">
            <div class="name">Grammar</div>
            <div class="count">{{ nGrammar }}</div>
          </div>
          <div v-if="nMissedWord" class="issuses-row">
            <div class="name">Missed word</div>
            <div class="count">{{ nMissedWord }}</div>
          </div>
          <div v-if="nPunctuation" class="issuses-row">
            <div class="name">Punctuation</div>
            <div class="count">{{ nPunctuation }}</div>
          </div>
          <div v-if="nVocabulary" class="issuses-row">
            <div class="name">Vocabulary</div>
            <div class="count">{{ nVocabulary }}</div>
          </div>
          <div v-if="nWordOrder" class="issuses-row">
            <div class="name">Word order</div>
            <div class="count">{{ nWordOrder }}</div>
          </div>
          <div v-if="nWritingIssues" class="issuses-row">
            <div class="name">Writing issues</div>
            <div class="count">{{ nWritingIssues }}</div>
          </div>
          <div v-if="nVerbPosition" class="issuses-row">
            <div class="name">Verb position</div>
            <div class="count">{{ nVerbPosition }}</div>
          </div>
        </div>
        <div v-else class="no-issuses-box d-flex flex-column text-center">
          <img src="../assets/images/no-issues.png" alt="No issues" class="no-issues-img mb-4">
          <b>Great work!</b>
          No issues in this text
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Grade from '../components/controls/Grade'
import Notes from '../components/controls/Notes'

import VeeFormValidation from '@/utils/VeeFormValidation'
import RedBorder from '../components/RedBorder'

export default {
  components: {
    Notes,
    Grade,
    RedBorder
  },
  data() {
    return {
      rules2: {
        grade: [
          {
            required: true,
            message: 'Please, enter grade'
          }
        ]
      },
      errors: {}
    }
  },
  computed: {
    flatValues () {
      return {
        ...this.$store.state.query
      }
    },
    issuesAmount() {
      return this.$store.state.g.corrections
        .reduce((counter, crn) => {
          if (crn.type === 'correction') {
            return counter + crn.tags.length
          } else {
            return counter
          }
        }, 0)
    },
    nSpelling() {
      return this.$store.state.g.corrections.filter(
        crn => crn.tags.indexOf('spelling') !== -1
      ).length
    },
    nGrammar() {
      return this.$store.state.g.corrections.filter(
        crn => crn.tags.indexOf('grammar') !== -1
      ).length
    },
    nMissedWord() {
      return this.$store.state.g.corrections.filter(
        crn => crn.tags.indexOf('missed_word') !== -1
      ).length
    },
    nPunctuation() {
      return this.$store.state.g.corrections.filter(
        crn => crn.tags.indexOf('punctuation') !== -1
      ).length
    },
    nVocabulary() {
      return this.$store.state.g.corrections.filter(
        crn => crn.tags.indexOf('vocabulary') !== -1
      ).length
    },
    nWordOrder() {
      return this.$store.state.g.corrections.filter(
        crn => crn.tags.indexOf('word_order') !== -1
      ).length
    },
    nWritingIssues() {
      return this.$store.state.g.corrections.filter(
        crn => crn.tags.indexOf('writing_issues') !== -1
      ).length
    },
    nVerbPosition() {
      return this.$store.state.g.corrections.filter(
        crn => crn.tags.indexOf('verb_position') !== -1
      ).length
    }
  },
  mounted() {
    this.validator = new VeeFormValidation(this.flatValues, this.rules2, (key, message) => {
      this.errors[key] = message
    })
    this.$store.state.forceCheck1 = this.validator.forceCheck.bind(this.validator)
    this.$store.state.forceCheck2Rules = this.rules2
  }
}
</script>

<style lang="scss" scoped>
.part-two {
  padding: 0px 32px;
  flex-grow: 1;
}

.issuses-box {
  width: 288px;
  background: #f0f4f9;
  border-radius: 20px;
  padding: 24px 32px;
}

.issuses-title {
  // font-weight: 700;
  font-size: 24px;
  margin-bottom: 24px;
}

.blue {
  font-weight: 700;
  color: #258ffb;
}

.issuses-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;

  .name {
    font-size: 16px;
    color: #323232;
  }

  .count {
    width: 24px;
    height: 24px;
    background: #ff7789;
    border-radius: 50%;

    font-size: 16px;
    color: #ffffff;
    text-align: center;
  }
}

.no-issuses-box {
  width: 260px;
  background: #f0f4f9;
  border-radius: 20px;
  padding: 27px 0px;
}

.no-issues-img {
  width: 128px;
  height: 91px;
  margin: auto;
}
</style>
