<template>
  <div
    v-show="isShow"
    class="text-editor-tooltip"
    ref="text_editor_tooltip"
    v-click-outside="closeByOutside"
    @mouseup="stop"
  >
    <CorrectionForm
      v-if="g.correction.type === 'correction'"
      :isReadonly="true"
      @close="close"
      @cancel="cancel"
    />
    <CommentForm
      v-if="g.correction.type === 'comment'"
      :isReadonly="true"
      @close="close"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import CorrectionForm from './CorrectionForm'
import CommentForm from './CommentForm'
import ClickOutside from '@/directives/click-outside'
import { onlyDeleteSelection } from '@/utils/CorrectionUtils'

export default {
  name: 'TextReaderTooltip',
  components: {
    CorrectionForm,
    CommentForm
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      g: this.$store.state.g,
      isShow: false,
      isOutside: false
    }
  },
  methods: {
    stop(e) {
      // stop event for disable editor mouseup handler
      e.stopPropagation()
    },
    recreatePopper(el) {
      if (this.g.popper && this.g.popper.destroy) {
        this.g.popper.destroy()
      }
      this.g.popper = createPopper(el, this.$refs.text_editor_tooltip, {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              bondary: this.g.editorElement,
              altBoundary: true
            }
          }
        ]
      })
    },
    close() {
      this.g.popper.destroy()
      this.isShow = false
      // this.isOutside = false // = false in watch before setTimeOut
    },
    cancel() {
      // delete only if correction no created (isNew = true)
      if (this.g.correction.isNew) {
        onlyDeleteSelection(
          this.g.editorElement,
          this.g.correction.id,
          this.g.correction.oldHtml
        )
        this.g.correction.isNew = false
      }
      this.close()
      this.g.correctionElement = null
    },
    closeByOutside(e) {
      if (e.classList && e.classList.contains('correction')) {
        // no hide tooltip when me click corection for edit
        return
      }

      if (this.isOutside) {
        this.cancel()
      }
    }
  },
  computed: {
    correctionElement() {
      return this.$store.state.g.correctionElement
    }
  },
  watch: {
    correctionElement(newValue) {
      if (newValue) {
        this.isShow = true
        this.recreatePopper(this.g.correctionElement)
        this.isOutside = false
        // TODO: Rewrite this (mousedown .. long time .. mouseup -> hide)
        setTimeout(() => {
          this.isOutside = true
        }, 500)
      } else {
        this.close()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text-editor-tooltip {
  // border: 1px solid #a8a3a3;
  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  border-top: 1px solid #a8a3a3;
  border-left: 1px solid #a8a3a3;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.text-editor-tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -5px;
}

.text-editor-tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -5px;
  left: calc(50% - 3px);
}

.text-editor-tooltip[data-popper-placement^='left'] > #arrow {
  right: -5px;
}

.text-editor-tooltip[data-popper-placement^='right'] > #arrow {
  left: -5px;
}
.minus_pad {
  margin: -20px;
}
.btn-item {
  width: 160px;
  border: 0;
  z-index: 100;
  position: relative;
  // outline:1px solid red;
}

.btn-item:hover {
  background: #abd4fe;
}
</style>
