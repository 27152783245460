<template>
  <router-view/>
</template>

<script>
export default {
  name: 'app',
  mounted() {
    const isNightMode = window.matchMedia('(prefers-color-scheme: dark)').matches

    if (isNightMode) {
      window.ym(85394716, 'reachGoal', 'night_mode')
    }
  }
}
</script>

<style>
</style>
