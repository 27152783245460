<template>
  <div
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div v-if="message" class="modal-body">
          <div class="">
            {{ message }}
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <div></div>
          <div>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ buttonNo }}
            </button>
            &nbsp;
            <button type="button" class="btn btn-primary" @click="yes">
              {{ buttonYes }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'

export const asyncBootstrapModal = function (id) {
  const el = document.getElementById(id)
  const myModal = new Modal(el, {})
  myModal.show()

  return new Promise((resolve, reject) => {
    el.addEventListener('hide.bs.modal', () => {
      reject(new Error('modal reject'))
    })

    el.addEventListener('onYes.bs.modal', () => {
      resolve(true)
      myModal.hide()
    })
  })
}

export default {
  name: 'ConfirmModal',
  props: {
    title: {
      type: String,
      default: 'Modal Confirm Window'
    },
    message: {
      type: String,
      default: ''
    },
    buttonYes: {
      type: String,
      default: 'Yes'
    },
    buttonNo: {
      type: String,
      default: 'No'
    }
  },
  methods: {
    yes() {
      const event = new Event('onYes.bs.modal', {
        bubbles: true,
        cancelable: true
      })
      this.$refs.modal.dispatchEvent(event)
    }
    // no() {
    //   const event = new Event('onNo.bs.modal', {
    //     bubbles: true,
    //     cancelable: true,
    //   });
    //   this.$refs.modal.dispatchEvent(event);
    // }
  }
}
</script>

<style scoped></style>
