<template>
  <transition name="fade">
    <div v-if="displayCorrection">
      <div class="slide-up-overflow" @click="setDisplayCorrection(null)"></div>
      <div class="slide-up-form">
        <CorrectionItemMobile :isReadonly="true" :correction="displayCorrection" />
      </div>
    </div>
  </transition>
</template>

<script>
import CorrectionItemMobile from './CorrectionItemMobile'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'SlideUpForm',
  components: { CorrectionItemMobile },
  computed: {
    ...mapState(['displayCorrection'])
  },
  methods: {
    ...mapMutations(['setDisplayCorrection'])
  }
}
</script>

<style scoped>
.slide-up-overflow {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  height: 100%;
  width: 100%;
}

.slide-up-form {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  max-height: 92%;
  padding: 16px;
  overflow-y: scroll;
  --tw-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-opacity));
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
</style>
