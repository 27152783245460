<template>
  <div :class="{ mobile: isMobile }">
    <div class="text-mode-wrap">
      <div
        class="text-mode-item"
        :class="{
          tm_active: modelValue === 'original_text',
          tm_inactive: modelValue !== 'original_text'
        }"
        @click="$emit('update:modelValue', 'original_text')"
      >
        Original text
      </div>
      <div
        class="text-mode-item"
        :class="{
          tm_active: modelValue === 'with_corrections',
          tm_inactive: modelValue !== 'with_corrections'
        }"
        @click="$emit('update:modelValue', 'with_corrections')"
      >
        Corrected text
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextModeChanger',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue']
}
</script>

<style scoped lang="scss">
.text-mode-wrap {
  display: inline-flex;
  gap: 2rem;
}

.text-mode-item {
  color: #258ffb;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.045em;
  text-transform: uppercase;

  padding: 8px 0px;
  cursor: pointer;
}

.tm_active {
  border-bottom: #258ffb solid 1px;
}

.tm_inactive {
  color: #bfbfbf;
  border-bottom: #ffffff solid 1px;
}

.tm_inactive:hover {
  border-bottom: #bfbfbf solid 1px;
}

.mobile {
  .text-mode-wrap {
    width: 100%;
    gap: 0px;
    border-bottom: #bfbfbf solid 1px;
  }

  .text-mode-item {
    padding: 8px 16px;
  }

  @media screen and (max-width: 600px) {
    .text-mode-wrap {
      border-bottom: 0px;
    }

    .text-mode-item {
      padding: 8px 0px;
      width: 50%;
    }
  }
}
</style>
