<template>
  <div class="page desktop-view">
    <div class="controls-block">
      <RedBorder :error="errors.assignmentLevel">
        <LanguageLevel v-model="$store.state.query.assignmentLevel" />
      </RedBorder>
      <RedBorder :error="errors.studentGroupName">
        <UiSelect
          placeholder="Select the group"
          :items="groups"
          v-model="studentGroupName"
        />
      </RedBorder>
      <RedBorder :error="errors.assignmentTypeName">
        <UiSelect
          placeholder="Select the type"
          :items="assignments"
          v-model="assignmentTypeName"
        />
      </RedBorder>
      <div class="spacer"></div>
      <TextModeChanger v-model="textMode" />
    </div>
    <div class="content">
      <!--TODO: refactor if and show-->
      <main>
        <TextEditor
          v-show="!isReadonly"
          height1
          disableEditAfterFirstCorrection
          :isReadonly="textMode !== 'original_text'"
          v-model="originalText"
        />
        <TextReader v-show="isReadonly" :value="redactedText" />
        <TextEditorTooltip v-if="!isReadonly" />
        <TextReaderTooltip v-else />
      </main>
      <SideBar :textMode="textMode" />
    </div>
  </div>

  <TeachersModal />

  <ConfirmModal
    id="confirmClear"
    title="Clear all the corrections?"
    message="After you clear it, you lose all the corrections you’ve made."
    button-no="Cancel"
    button-yes="Yes, clear it"
  />

  <ConfirmModal
    id="confirmClear_2"
    title="Clear all the text?"
    message="After you clear it, you lose all the text you’ve made."
    button-no="Cancel"
    button-yes="Yes, clear it"
  />

  <ConfirmModal
    id="confirmDelete"
    title="Delete correction?"
    message="After you delete it, you’ll lose your correction and comment."
    button-no="Cancel"
    button-yes="Yes, delete it"
  />
</template>

<script>
import TextEditor from '../components/TextEditor'
import SideBar from '../components/SideBar'
import TextEditorTooltip from '../components/TextEditorTooltip'
import TextReaderTooltip from '../components/TextReaderTooltip'
import LanguageLevel from '../components/controls/LanguageLevel'
import UiSelect from '../components/controls/UiSelect'
import TeachersModal from '../components/modals/TeachersModal'
import TextModeChanger from '../components/controls/TextModeChanger'

import VeeFormValidation from '@/utils/VeeFormValidation'
import RedBorder from '../components/RedBorder'
import ConfirmModal from '../components/modals/ConfirmModal'
import TextReader from '../components/TextReader'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Page',
  components: {
    TextReader,
    ConfirmModal,
    RedBorder,
    TextModeChanger,
    TeachersModal,
    UiSelect,
    LanguageLevel,
    TextEditorTooltip,
    TextReaderTooltip,
    SideBar,
    TextEditor
  },
  data() {
    return {
      isReadonly: false,
      textMode: 'original_text',
      groups: [
        'Batch 10 Goethe',
        'Batch 10 Telc',
        'Batch 14',
        'Batch 15',
        'Batch 18',
        'Batch 19',
        'Batch 20A',
        'Batch 20B',
        'Batch 22A',
        'Batch 22B',
        'Batch 24A',
        'Batch 24B',
        'Batch 25A',
        'Batch 25B',
        'Batch 26A',
        'Batch 26B',
        'Batch 26C',
        'Batch JCJ 1',
        'External'
      ],
      assignments: [
        'E-Mail',
        'Brief',
        'Beschwerde',
        'Bitte um',
        'Einladung',
        'Absage',
        'Forumsbeitrag',
        'Sonstiges',
        'External'
      ],

      rules1: {
        assignmentLevel: [
          {
            required: true,
            message: 'Please, enter assignment level'
          }
        ],
        studentGroupName: [
          {
            required: true,
            message: 'Please, enter student group'
          }
        ],
        assignmentTypeName: [
          {
            required: true,
            message: 'Please, enter assignment type'
          }
        ]
      },
      errors: {}
    }
  },
  computed: {
    studentGroupName: {
      get() {
        return this.$store.state.query.studentGroupName
      },
      set(value) {
        this.setStudentGroupName(value)
      }
    },
    assignmentTypeName: {
      get() {
        return this.$store.state.query.assignmentTypeName
      },
      set(value) {
        this.setAssignmentTypeName(value)
      }
    },
    originalText: {
      get() {
        return this.$store.state.query.originalText
      },
      set(value) {
        this.updateTexts(value)
      }
    },
    redactedText() {
      return this.$store.state.query.redactedText
    },
    flatValues() {
      return {
        ...this.$store.state.query
      }
    }
  },
  watch: {
    textMode(newVal) {
      this.isReadonly = newVal === 'with_corrections'
    },
    flatValues() {
      this.validator.watchCheck(this.flatValues, this.rules1)
    }
  },
  mounted() {
    this.validator = new VeeFormValidation(
      this.flatValues,
      this.rules1,
      (key, message) => {
        // for vue v2 : this.$set(this.errors, key, message);
        this.errors[key] = message
      }
    )
    // todo: move header logic to this page
    this.$store.state.forceCheck1 = this.validator.forceCheck.bind(
      this.validator
    )
    this.$store.state.forceCheck1Rules = this.rules1
  },
  methods: {
    ...mapMutations(['setStudentGroupName', 'setAssignmentTypeName']),
    ...mapActions(['updateTexts'])
  }
}
</script>

<style>
.desktop-view {
  min-width: 1030px;
}

.btn-primary {
  background: #258ffb !important;
  border-color: #52a7ff;
}
</style>

<style scoped lang="scss">
.page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
}

.spacer {
  flex-grow: 1;
}

.content {
  flex-grow: 1;
  padding: 16px 32px;
  display: flex;

  main {
    flex-grow: 1;
  }
}

.controls-block {
  display: flex;
  align-items: center;
  min-height: 80px;
  padding: 0px 32px;
}
</style>
