<template>
  <div :class="['message-wrapper', 'py-3', 'px-4', type]">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div v-if="closeable" class="close" @click="$emit('close')"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SideMessage',
  props: {
    title: String,
    closeable: Boolean,
    type: {
      type: String,
      default: 'info'
    }
  }
}
</script>

<style scoped lang="scss">
.message-wrapper {
  width: 400px;
  box-sizing: border-box;
  border-radius: 4px;

  .header {
    display: flex;

    .title {
      flex-grow: 1;
      font-family: Helvetica;
      font-size: 24px;
      font-weight: bold;
    }

    .close {
      width: 12px;
      height: 12px;
      background-image: url('~@/assets/images/close.svg');
      margin-top: 8px;
      cursor: pointer;
    }
  }
}

.info {
  background: #d1ecf1;
  border: 1px solid #bee5eb;
  color: #0c5460;
}

.warn {
  background: #FFF3CD;
  border: 1px solid #FFEEBA;
  color: #856404;
}
</style>
