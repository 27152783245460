<template>
  <div
    id="myModal2"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title d-flex align-items-center"
          >
            Hi there! Let's identify you &nbsp;
            <div class="emoji"></div>
          </h5>
        </div>
        <div class="modal-body">
          <label for="teacherEmail" class="form-label"
            >What's your work email?</label
          >
          <input
            id="teacherEmail"
            type="email"
            class="form-control mb-1"
            placeholder="teacher@example.com"
            v-model.trim="$store.state.query.teacherEmail"
          />
          <div class="modal-help">
            <span
              v-if="typeof isEmailAllowed === 'undefined' || isEmailAllowed"
            >
              We need it to track your progress
            </span>
            <span v-else class="not-allowed">
              Your email is not allowed!
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="getStarted"
            :disabled="isDisabled || !isEmailAllowed"
          >
            Get started
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'

const EMAIL_WHITELIST = [
  'a.baecker@germanlanguage.ph',
  'c.scott@germanlanguage.ph',
  'j.pellier@germanlanguage.ph',
  'k.labandelo@germanlanguage.ph',
  'l.wuthrich@germanlanguage.ph',
  'vladimir.malkov@tappysoft.com',
  'renat.knyazev@tappysoft.com',
  'evgenia.yakovleva@tappysoft.com',
  'nikita@tappysoft.com',
  'm.mueller@germanlanguage.ph'
]

export default {
  name: 'TeachersModal',
  data() {
    return {
      isEmailAllowed: undefined
    }
  },
  computed: {
    isDisabled() {
      function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
      }

      return !validateEmail(this.$store.state.query?.teacherEmail)
    },
    teacherEmail() {
      return this.$store.state.query.teacherEmail
    }
  },
  watch: {
    teacherEmail(value) {
      if (!this.isDisabled) {
        this.isEmailAllowed = EMAIL_WHITELIST.includes(value)
      }
    }
  },
  mounted() {
    const myModal = new Modal(document.getElementById('myModal2'), {
      backdrop: 'static',
      keyboard: false
    })

    const teacherEmail = localStorage.getItem('teacherEmail')
    if (teacherEmail) {
      this.$store.state.query.teacherEmail = teacherEmail
    } else {
      this.$store.state.query.teacherEmail = ''
      myModal.show()
    }

    this.myModal = myModal
  },
  methods: {
    getStarted() {
      if (this.$store.state.query.teacherEmail) {
        localStorage.setItem(
          'teacherEmail',
          this.$store.state.query.teacherEmail
        )
      }
      this.myModal.hide()
    }
  }
}
</script>

<style scoped>
.emoji {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('~@/assets/images/teachers_email.png');
  background-size: contain;
}

.not-allowed {
  color: red;
}
</style>
